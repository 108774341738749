<template>
    <div>
        <b-row>
            <b-col cols="12">

                <b-alert :show="errors.length > 0" v-for="(item, index) in errors" :key="index" variant="danger"
                    dismissible>
                    {{item.message}}</b-alert>
            </b-col>

        </b-row>
        <b-row v-if="showNoLocationAvailable == true" class="mt-2">
            <b-col cols="12">
                
      <b-alert :show="true" variant="warning" dismissible>No devices available to load data</b-alert>
            </b-col>
            
        </b-row>
        <b-row v-if="isLoading" align-h="center" class="mt-5" align-v="center">
          <b-spinner style="width: 5rem; height: 5rem;" label="Large Spinner"></b-spinner>
        </b-row>
        <b-row v-if="!isLoading">
            <b-col cols="12">
                <b-card class="card-custom-padding">
                    <b-row>
                        <b-col cols="12">
                            <label for="example-input">Choose a date</label>
                        </b-col>
                    </b-row>
                    <b-row class="mt-0">
                        <b-col cols="8" lg="5">
                            <b-input-group class="mb-3">
                                <b-form-input id="example-input" v-model="selectedDate" type="text"
                                    placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>
                                <b-input-group-append>
                                    <b-form-datepicker v-model="selectedDate" button-only right locale="en-US"
                                        aria-controls="example-input"></b-form-datepicker>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                        <b-col cols="4" lg="2" offset-lg="5">
                            <b-button block variant="primary" @click="searchDate()">View Date</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-row align-v="center" v-if="!isLoading">
            <b-col cols="12" lg="9">
                <b-card class="skeleton-for-line-graph">
                    <b-skeleton-img v-if="isLoading"></b-skeleton-img>
                    <line-chart v-if="!isLoading" :data="chartData" :options="chartOptions" :height="80" class="firstgraph"></line-chart>
                    <line-chart v-if="!isLoading" :data="chartData" :options="chartOptions" :height="120" class="secondgraph"></line-chart>
                </b-card>
            </b-col>
            <b-col cols="12" lg="3" class="description-column">
                <b-card class="primary-background text-center">
                    <p>Total for date:</p>
                    <h4>{{totalReading}} kW</h4>
                    <p>Average per hour</p>
                    <h4>{{averageReading}} kWh</h4>
                    <div v-if="showStatus == true">
                        <p>Current Status:</p>
                        <b-button disabled variant="gray" v-if="deviceStatus === false">Off</b-button>
                        <b-button disabled variant="primary" v-if="deviceStatus === true">On</b-button>
                    </div>
                </b-card>
            </b-col>
        </b-row>

        <b-row v-if="!isLoading">
            <b-col cols="12">
                <b-card class="mb-5">
                    <b-row v-if="isLoading" class="mr-2">
                        <b-col cols="12" class="ml-2">
                            <b-skeleton-table :rows="10" :columns="4" :table-props="{ bordered: false, striped: true }">
                            </b-skeleton-table>
                        </b-col>
                    </b-row>

                    <b-table striped small hover v-if="!isLoading" :items="items" :fields="fields" align-v="center"
                        id="summary-table" class="mb-0" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {
        bus
    } from '../main';
    import LineChart from "@/components/FilledLineChart.js";
    import {
        mapActions
    } from 'vuex';
    export default {
        components: {
            LineChart
        },
        data: () => ({
            selectedDate: false,
            deviceStatus: false,
            averageReading: 0,
            isLoading: false,
            showStatus: false,
            totalReading: 0,
            sortDesc: false,
            sortBy: 'date',
            items: [],
            isLoadingSummary: false,
            errors: [],
            fields: [{
                    label: 'Time',
                    key: 'time',
                    sortable: true
                },
                {
                    label: 'Average per Minute (kWh)',
                    key: 'averageKwh',
                    sortable: true
                },
                {
                    label: 'Total (kWh)',
                    key: 'totalKwh',
                    sortable: true
                }
            ],
            chartData: {},
            chartOptions: {
                responsive: false,
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false
                }
            },
      showNoLocationAvailable: false
        }),
        created() {
            bus.$on('changedLocation', () => {
                this.doApiCall()

            })
            this.selectedDate = this.concatDate(new Date())
            this.doApiCall()
        },
        methods: {
            ...mapActions(['getDateSummaryForSolar']),
            concatDate(date) {
                let year = date.getFullYear()
                let month = date.getMonth() + 1
                let day = date.getDate()

                let returnDate = year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0')
                return returnDate
            },
            isDateSame() {
                let today = this.concatDate(new Date)
                if (today == this.selectedDate) {
                    this.showStatus = true
                } else {
                    this.showStatus = false
                }
            },
            searchDate() {
                this.doApiCall()
            },
            doApiCall() {
            this.errors = []
                let request = {
                    selectedDate: this.selectedDate
                }
                this.$store.commit('setGetSummaryDataRequest', request)
                this.isLoading = true

                this.getDateSummaryForSolar()
                    .then((response) => {
            if (response.data !== null) {
                        let infoLabels = []
                        let info = []
                    this.items =[]
                        this.summaryData = []
                        response.data.data.forEach(item => {
                            let indexValue = item.dayTime.indexOf("T")
                            let time = item.dayTime.substr(indexValue + 1, 5)
                            infoLabels.push(time)
                            info.push(item.kwh.toFixed(2))
                            this.items.push({
                                time: time,
                                averageKwh: item.avKWpH.toFixed(2),
                                totalKwh: item.kwh.toFixed(2)
                            })
                        });
                        // this.items.push({time: '', averageKwh: '', totalKwh: total.toFixed(2)})

                        this.totalReading = response.data.totalKWH.toFixed(2)
                        this.averageReading = response.data.averageKWpH.toFixed(2)
                        this.deviceStatus = response.data.status

                        this.chartData = {
                            hoverBackgroundColor: "red",
                            hoverBorderWidth: 10,
                            labels: infoLabels,
                            datasets: [{
                                label: 'Total kWh',
                                backgroundColor: '#65BC6E',
                                pointBackgroundColor: 'white',
                                borderWidth: 1,
                                pointBorderColor: '#65BC6E',
                                data: info
                            }]
                        }
            }
            else{
              this.showNoLocationAvailable = true
            }

                        this.isLoading = false
                    })
                    .catch(() => {
                        this.isLoading = false
                        this.errors.push({
                            message: 'Failed to load data. Please refresh and try again.'
                        })
                    })

                this.isDateSame()
            }
        }
    }
</script>